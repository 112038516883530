import React from "react";
import { useParams } from "react-router-dom";
import { blogPosts } from "../blogData";
import './BlogPost.css';

const BlogPost = () => {
  const { pramlink } = useParams(); // Access the title from the URL
  const decodedTitle = decodeURIComponent(pramlink); // Decode the title (in case it has spaces or special characters)

  // Find the post by comparing titles (case-sensitive)
  const post = blogPosts.find((post) => post.pramlink === decodedTitle);

  if (!post) return <h2>Post Not Found</h2>;

  // Function to render the content based on its type
  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.type) {
        case "h2":
          return <h2 key={index}>{block.text}</h2>;
        case "h3":
          return <h3 key={index}>{block.text}</h3>;
        case "p":
          return <p key={index}>{block.text}</p>;
        case "ul":
          return (
            <ul key={index}>
              {block.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        case "ol":
          return (
            <ol key={index}>
              {block.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ol>
          );
        case "a":
          return (
            <a key={index} href={block.href} target="_blank" rel="noopener noreferrer">
              {block.text}
            </a>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="blog-post">
      <h1>{post.title}</h1>
      <p>
        By {post.author}
      </p>
      <div>{renderContent(post.content)}</div>
    </div>
  );
};

export default BlogPost;
