import React from "react";
import { blogPosts } from "../blogData";
import { Link } from "react-router-dom";
import './BlogList.css';

const BlogList = () => {
  return (
    <div className="blog-container">
      <h1 className="blog-title">Blog</h1>
      <div className="blog-list">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-list-item">
            <h2>
              {/* Encode the title to make it URL safe */}
              <Link to={`/blog/${encodeURIComponent(post.pramlink)}`}>{post.title}</Link>
            </h2>
            <p>
              By {post.author} 
            </p>
            <p>
              {/* Check if content is an array and handle accordingly */}
              {Array.isArray(post.content)
                ? post.content.find((block) => block.type === "p")?.text.substring(0, 170)
                : post.content.substring(0, 170)}...
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
