import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CodeDiffHighlight from './components/CodeDiffHighlight';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';
import FAQ from './components/FAQ';
import Disclaimer from './components/Disclaimer';
import './App.css'; // Import the CSS file
import BlogList from "./components/BlogList";
import BlogPost from "./components/BlogPost";
import NotFound from "./components/NotFound"
function App() {
  // State to handle the mobile menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu visibility on mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      {/* Navigation links */}
      <nav className="navbar">
        {/* Hamburger Menu Icon */}
        <button className="hamburger-menu" onClick={toggleMenu}>
          <span className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`}></span>
        </button>

        <ul className={`navbar-list ${isMenuOpen ? 'open' : ''}`}>
          <li className="navbar-item">
            <Link to="/" className="navbar-link" onClick={closeMenu}>Home</Link>
          </li>
          <li className="navbar-item">
            <Link to="/about-us" className="navbar-link" onClick={closeMenu}>About</Link>
          </li>
          <li className="navbar-item">
            <Link to="/contact-us" className="navbar-link" onClick={closeMenu}>Contact</Link>
          </li>
          <li className="navbar-item">
            <Link to="/blog" className="navbar-link" onClick={closeMenu}>Blog</Link>
          </li>
 
        </ul>
      </nav>

      {/* Main content */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<CodeDiffHighlight />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:pramlink" element={<BlogPost />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<NotFound />} />


        </Routes>
      </div>

      {/* Footer */}
      <footer className="footer">
        <ul className="footer-links">
          <li className="footer-link-item">
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          </li>
          <li className="footer-link-item">
            <Link to="/cookie-policy" className="footer-link">Cookie Policy</Link>
          </li>
          <li className="footer-link-item">
            <Link to="/faq" className="footer-link">FAQ</Link>
          </li>
          <li className="footer-link-item">
            <Link to="/Disclaimer" className="footer-link">Disclaimer</Link>
          </li>
        </ul>
        <p>&copy; 2024 metamindforge.com. All rights reserved.</p>
      </footer>
    </Router>
  );
}

export default App;
