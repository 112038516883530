import React from 'react';
import './Disclaimer.css';  // Import the CSS file for the Disclaimer

const Disclaimer = () => {
    return (
        <div className="disclaimer-container">
            <h1 className="disclaimer-title">Disclaimer</h1>
            
            <section>
                <h2>General Information</h2>
                <p>
                    The information provided on this website is for general informational purposes only. We strive to keep the information accurate and up to date, but we make no guarantees regarding the completeness, accuracy, or reliability of the information.
                </p>
            </section>
            
            <section>
                <h2>Professional Advice</h2>
                <p>
                    The content of this website is not intended to substitute for professional advice, whether legal, medical, financial, or any other type. Always seek the advice of a qualified professional with any questions you may have.
                </p>
            </section>
            
            <section>
                <h2>Limitation of Liability</h2>
                <p>
                    We shall not be held liable for any direct, indirect, incidental, special, or consequential damages that may arise from your use of this website. This includes any reliance on the information provided on this site.
                </p>
            </section>
            
            <section>
                <h2>External Links</h2>
                <p>
                    Our website may contain links to external websites. We do not control or endorse the content of these third-party sites and are not responsible for any content or services provided by them.
                </p>
            </section>
            
            <section>
                <h2>Changes to the Disclaimer</h2>
                <p>
                    We reserve the right to modify this Disclaimer at any time. Any changes will be posted on this page, and the "last updated" date will be updated accordingly.
                </p>
            </section>
        </div>
    );
};

export default Disclaimer;
