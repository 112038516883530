import React from 'react';
import './Contact.css'

function ContactUs() {
  return (
<div className="contact-us">
  <h2>Contact Us</h2>
  <p>We'd love to hear from you! Whether you have questions, feedback, or just want to learn more, we're here to help.</p>
  <p>Our support team is ready to assist you with any inquiries. Get in touch, and we’ll get back to you as soon as possible.</p>
  <p><strong>Email Us:</strong> <a href="mailto:support@metamindforge.com">support@MetaMindForge.com</a></p>
  <p>Your thoughts and suggestions are always welcome. We strive to improve and make your experience with MetaMindForge.com better!</p>
</div>

  );
}

export default ContactUs;
