import React from 'react';
import './CookiePolicy.css';  // Import the CSS file for the Cookie Policy

const CookiePolicy = () => {
    return (
        <div className="cookie-policy-container">
            <h1 className="cookie-policy-title">Cookie Policy</h1>
            
            <section>
                <h2>Introduction</h2>
                <p>
                    This Cookie Policy explains how we use cookies on our website. By using our website, you consent to the use of cookies as outlined in this policy.
                </p>
            </section>
            
            <section>
                <h2>What are Cookies?</h2>
                <p>
                    Cookies are small text files placed on your device by a website. They help us improve your experience, and they can store various types of data, including preferences, settings, and analytics.
                </p>
            </section>
            
            <section>
                <h2>Types of Cookies We Use</h2>
                <ul>
                    <li><strong>Essential Cookies:</strong> These cookies are necessary for the operation of our website and cannot be turned off.</li>
                    <li><strong>Performance Cookies:</strong> These cookies help us analyze how our website is used and improve its performance.</li>
                    <li><strong>Functional Cookies:</strong> These cookies allow us to remember your preferences and provide enhanced features.</li>
                    <li><strong>Advertising Cookies:</strong> These cookies are used to track your browsing habits and provide relevant advertisements.</li>
                </ul>
            </section>
            
            <section>
                <h2>Managing Cookies</h2>
                <p>
                    You can control and manage cookies through your browser settings. Most browsers allow you to block cookies or delete them. However, blocking or deleting cookies may impact the functionality of our website.
                </p>
            </section>
            
            <section>
                <h2>Consent</h2>
                <p>
                    By using our website, you consent to the use of cookies as described in this Cookie Policy. If you do not agree with the use of cookies, you should disable them or refrain from using our website.
                </p>
            </section>
            
            <section>
                <h2>Changes to this Cookie Policy</h2>
                <p>
                    We may update this Cookie Policy from time to time. Any changes will be posted on this page, and the updated date will be indicated at the top of the page.
                </p>
            </section>
        </div>
    );
};

export default CookiePolicy;
