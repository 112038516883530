import React from 'react';
import './FAQ.css'; // Import the CSS file for the FAQ page

const FAQ = () => {
    return (
        <div className="faq-container">
            <h1 className="faq-title">Frequently Asked Questions (FAQ)</h1>
            
            <section>
                <h2>1. What is the Online Code Comparison Tool?</h2>
                <p>The Online Code Comparison Tool is a web-based tool that allows developers and content creators to compare two versions of text or code. It highlights the differences between the original and modified versions, helping you identify changes quickly and efficiently.</p>
            </section>

            <section>
                <h2>2. Do I need to create an account to use the tool?</h2>
                <p>No, you do not need to create an account to use the tool. Our code comparison tool is free to use without any registration or login required. Simply paste your code or text and start comparing right away!</p>
            </section>

            <section>
                <h2>3. What types of files can I compare using the tool?</h2>
                <p>You can compare any type of text-based files using our tool. This includes programming code (like Python, JavaScript, HTML, CSS, etc.), markdown files, and plain text documents.</p>
            </section>

            <section>
                <h2>4. How does the code comparison work?</h2>
                <p>Our tool compares two versions of your code or text and highlights the changes between them. It shows you lines that have been added, removed, or modified. You simply paste the original and modified text into the tool's input areas, click "Compare," and the differences will be displayed.</p>
            </section>

            <section>
                <h2>5. Can I compare large files?</h2>
                <p>Yes, our tool is designed to handle large files, although there may be performance limitations depending on the size. For very large files, we recommend breaking them down into smaller chunks to ensure faster and more accurate comparisons.</p>
            </section>

            <section>
                <h2>6. Is the code comparison tool free?</h2>
                <p>Yes, the tool is completely free to use. There are no hidden costs or subscriptions. Simply visit the site, paste your text or code, and compare!</p>
            </section>

            <section>
                <h2>7. Is my data secure when using the tool?</h2>
                <p>We take your privacy seriously. The code or text you enter into the comparison tool is not stored or shared. Once you close your session, the data is discarded to ensure your privacy is protected.</p>
            </section>

            <section>
                <h2>8. Can I compare text files with different formatting or languages?</h2>
                <p>Yes, the tool supports comparing code and text files of various formats, including HTML, CSS, JavaScript, and more. It automatically highlights changes, regardless of the specific programming language or formatting used.</p>
            </section>

            <section>
                <h2>9. How accurate is the comparison?</h2>
                <p>The comparison is highly accurate, as the tool compares every line of the original and modified versions. It highlights added, removed, and modified lines of code or text with clear visual cues to help you easily identify the differences.</p>
            </section>

            <section>
                <h2>10. How can I get support if I have any issues?</h2>
                <p>If you encounter any issues or have questions about using the tool, feel free to reach out to us via our contact page. We're always happy to help!</p>
            </section>

        </div>
    );
};

export default FAQ;
