import React, { useState } from 'react';
import { diffLines } from 'diff';
import './CodeDiffHighlight.css'

const CodeDiffHighlight = () => {
  const [originalText, setOriginalText] = useState('');
  const [modifiedText, setModifiedText] = useState('');
  const [diffResult, setDiffResult] = useState([]);

  const handleCompare = () => {
    const diff = diffLines(originalText, modifiedText);
    setDiffResult(diff);
  };

  return (
    <div>
      {/* SEO Optimized Heading */}
      <h1 className="main-heading">
        Online Code Comparison Tool
      </h1>
      <p className="description">
        Effortlessly compare text or code. Highlight changes and see differences
        instantly with a clean, minimalist look.
      </p>

      {/* Input Areas */}
      <div className="textarea-container">
        <div className="textarea-section">
          <label className="textarea-label">Original Code/Text:</label>
          <textarea
            rows="15"
            className="textarea-input"
            value={originalText}
            onChange={(e) => setOriginalText(e.target.value)}
            placeholder="Paste your original code or text here..."
          />
        </div>
        <div className="textarea-section">
          <label className="textarea-label">Modified Code/Text:</label>
          <textarea
            rows="15"
            className="textarea-input"
            value={modifiedText}
            onChange={(e) => setModifiedText(e.target.value)}
            placeholder="Paste your modified code or text here..."
          />
        </div>
      </div>

      <button className="compare-button" onClick={handleCompare}>
        Compare
      </button>

      {/* Results */}
      <div>
        <h2 className="results-heading">Comparison Results</h2>
        <div className="results-output">
          {diffResult.map((part, index) => (
            <div
              key={index}
              className={`result-line ${
                part.added
                  ? 'line-added'
                  : part.removed
                  ? 'line-removed'
                  : 'line-unchanged'
              }`}
            >
              {part.value}
            </div>
          ))}
        </div>
      </div>

      {/* SEO Content */}
      <section className="seo-content">
        <h2>Why Use Our Online Code Comparison Tool?</h2>
        <p>
          In today’s fast-paced world, developers and software engineers often work with multiple versions of code or text. This can lead to the challenge of identifying and tracking the differences between two versions of a project, especially when managing complex codebases or collaborative projects. Our Online Code Comparison Tool is designed to help you effortlessly compare and highlight the changes between original and modified code or text, making it easier to understand, manage, and review your work.
        </p>
        <p>
          Whether you're a software developer, content writer, or someone working on any type of document, version control is crucial for maintaining an organized workflow. Our tool provides a clear, easy-to-understand comparison of the original and modified versions, highlighting added, removed, and unchanged lines. By using this tool, you can ensure that no change goes unnoticed and that the integrity of your work is preserved throughout the editing process.
        </p>
        <p>
          One of the most powerful features of our Code Comparison Tool is its simplicity. With no need to install software or deal with complex setups, you can start comparing code or text immediately. Simply paste your original and modified text into the respective boxes, click the "Compare" button, and within seconds, the differences will be displayed, making it effortless to spot changes.
        </p>
        <h3>Key Benefits of Using the Code Comparison Tool:</h3>
        <ul>
          <li><strong>Instant Comparison:</strong> You can instantly compare text or code without any complicated setup or installation process.</li>
          <li><strong>Clean and Minimalist Design:</strong> The tool provides a user-friendly, minimalist interface that ensures an excellent user experience.</li>
          <li><strong>Visualize Changes:</strong> Easily identify additions, deletions, and unchanged lines of code, helping you understand what has changed.</li>
          <li><strong>Compatible with Code and Text:</strong> Whether you're comparing programming code or general text, the tool is versatile and works for any type of content.</li>
          <li><strong>Save Time:</strong> By automating the comparison process, you can save hours that would otherwise be spent manually reviewing differences in large files.</li>
          <li><strong>Improve Accuracy:</strong> Visualizing code differences helps you ensure that all changes are intentional and accurate, reducing the chance of errors.</li>
        </ul>
        <p>
          Our tool is perfect for various use cases, including debugging code, reviewing content, and managing project revisions. By providing an instant visual representation of what has changed between two versions of your code or text, it becomes easier to manage software projects, collaborate with teams, or track content revisions. This helps software engineers, developers, and writers maintain a clean and accurate record of changes.
        </p>
        <p>
          With the growing need for version control in all types of projects, having a reliable and simple tool to compare code or text is more important than ever. Using our Online Code Comparison Tool allows you to gain quick insights into your work, enhancing your productivity and ensuring your code or text is error-free.
        </p>
        <p>
          Try out the Code Comparison Tool today and see how it can streamline your workflow, enhance your productivity, and help you focus on what matters most—writing clean, error-free code or text.
        </p>
      </section>
    </div>
  );
};

export default CodeDiffHighlight;
